import { UserVerifyEnum } from "@prisma/client";

import { Badge } from "@/components/tailwind/common/Badge";
import { trpc } from "@/util/trpc";

export const PendingVerificationBadge = () => {
  const { data: verificationCounts, isLoading } =
    trpc.cmsVerification.verificationCounts.useQuery();

  if (
    !verificationCounts?.countsByVerificationStatus[UserVerifyEnum.PENDING] ||
    isLoading
  )
    return null;

  return (
    <Badge
      name={verificationCounts?.countsByVerificationStatus[
        UserVerifyEnum.PENDING
      ].toString()}
      variant="inactive"
    />
  );
};
