export enum TicketStatusEnum {
  Pending = 0,
  Resolved = 2,
}

export enum TicketTypeEnum {
  USER = 1,
  ADMIN = 2,
}

export enum TicketDialogEnum {
  Edit = "edit",
}
