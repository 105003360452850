import { Badge } from "@/components/tailwind/common/Badge";
import { trpc } from "@/util/trpc";

import { TicketStatusEnum } from "./consts/enums";

export const ProcessingTicketNotification = () => {
  const { data: ticketCounts } = trpc.cmsTicket.ticketGroupCounts.useQuery();

  if (!ticketCounts?.[TicketStatusEnum.Pending]) return null;

  return (
    <Badge
      name={ticketCounts[TicketStatusEnum.Pending].toString()}
      variant="inactive"
    />
  );
};
